import { Link } from "gatsby"
import React, { useRef } from "react"
import AnimatedCaseStudy from "../../components/AnimatedCaseStudy/AnimatedCaseStudy"
import Button from "../../components/Button/Button"
import Hero from "../../components/Hero/Hero"
import Layout from "../../components/layout"
import { SEO } from "../../components/seo"

export const caseStudies = [
    {
        title: "Ski.com",
        img: "/case-study/ski/Thumb.png",
        slug: "ski",
        isPublished: true
    },
    {
        title: "Unmind",
        img: "/case-study/unmind/Thumb-unmind.png",
        slug: "unmind",
        isPublished: true
    },
    {
        title: "Mclaren Group",
        img: "/case-study/mclaren/Thumb.png",
        slug: "mclaren",
        isPublished: true
    },
    {
        title: "Sova Assessment",
        img: "/case-study/Sova.png",
        slug: "sova-assessment",
        isPublished: true
    },
    {
        title: "Mandala Partners",
        img: "/case-study/MDL.png",
        slug: "mandala-partners",
        isPublished: true
    },
    {
        title: "Gremlin Works",
        img: "/case-study/GremlinWorks.png",
        slug: "gremlin-works",
        isPublished: true
    },
    {
        title: "Celerity UK",
        img: "/case-study/celerity.png",
        slug: "celerity",
        isPublished: true
    },

    {
        title: "FLS",
        img: "/case-study/FLS.png",
        slug: "fls-group",
        isPublished: true
    },
    {
        title: "Purple horse",
        img: "/case-study/purple-horse.png",
        slug: "purple-horse",
        services: "marketing",
        isPublished: true
    },
    {
        title: "Saigon Baigur",
        img: "/case-study/saigon-baigur.png",
        slug: "saigon-baigur",
        isPublished: true
    }
]

const CaseStudy = ({ location }) => {
    const blockRef = useRef(null)
    const heroData = {
        title: "We're proud of what we do",
        description:
            "Below are some our of projects that we like to shout about. If you want to learn more about what we can do for you, reach out to us for a chat.",
        sub: ""
    }
    const context = {
        pageName: "SD | About",
        pageUri: location.href
    }

    return (
        <Layout context={context}>
            <Hero props={heroData} />
            <div
                ref={blockRef}
                className="case-study container grid gap-10 pb-20 sm:grid-cols-2 lg:grid-cols-12"
            >
                {caseStudies.map((item, index) => {
                    const { title, img, slug, isPublished } = item
                    const isLeft = index % 2 !== 1
                    return (
                        <AnimatedCaseStudy
                            isLeft={isLeft}
                            className={`case-study-item overflow-hidden lg:col-span-5 ${
                                isLeft ? "" : "lg:col-start-8 lg:mt-[110px]"
                            }`}
                        >
                            {isPublished ? (
                                <Link
                                    to={`/case-studies/${slug}`}
                                    className="group w-full"
                                >
                                    <div className="flex flex-col gap-[24px] ">
                                        <div className="aspect-square w-full overflow-hidden">
                                            <div className="h-full w-full transition-transform duration-700 group-hover:scale-105">
                                                <img
                                                    src={img}
                                                    className="img-top h-full w-full object-cover hover:scale-105"
                                                />
                                            </div>
                                        </div>
                                        <div>
                                            <p className="line-top">
                                                {item.services === "marketing"
                                                    ? "Web Design UI/UX & Marketing"
                                                    : "Web Design UI/UX & Development"}
                                            </p>
                                            <h4 className="title-bottom mt-2 text-lg font-semibold md:text-3xl ">
                                                {title}
                                            </h4>
                                        </div>
                                    </div>
                                </Link>
                            ) : (
                                <div className="group relative overflow-hidden">
                                    <img
                                        class="w-full transition-opacity duration-300 group-hover:z-10 group-hover:opacity-0"
                                        src={img}
                                        alt="case-study"
                                    />

                                    <div
                                        className="absolute inset-0 -z-[1] grid place-items-center opacity-0 transition-opacity duration-300 group-hover:z-10 group-hover:opacity-100"
                                        style={{
                                            background:
                                                "linear-gradient(180deg, #4674F6 0%, #5581FF 30.73%, #FFF 100%)"
                                        }}
                                    >
                                        <div className="text-center text-white">
                                            <h3 className="text-lg font-medium lg:mb-4 lg:text-[22px]">
                                                saigon
                                                <span className="font-bold">
                                                    digital.
                                                </span>
                                            </h3>
                                            <p className="font-['Poppins'] text-3xl font-semibold leading-[1.4] lg:text-[40px]">
                                                COMING SOON
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </AnimatedCaseStudy>
                    )
                })}
            </div>
            {/* <div class="container flex justify-center">
                <Button type="blue">Explore More</Button>
            </div> */}
        </Layout>
    )
}

export default CaseStudy

export const Head = () => (
    <SEO
        title={"Case Studies | Saigon Digital"}
        description={
            "Discover our collection of illuminating case studies on our blog. Dive into real-world examples of how we've helped clients overcome obstacles, achieve goals, and thrive in their industries. Explore the innovative strategies and data-backed insights that power these success stories, providing valuable inspiration and knowledge for your business endeavors."
        }
        keywords={
            "web design saigon, web development saigon, jamstack development, jamstack web development saigon"
        }
    />
)
